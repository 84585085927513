import {post,get} from '@/utils/request'

//区域列表
export function PostRegionalList(data) {
  return post('adminapi/regional_list',data)
}
//地区区域
export function PostRegionalAdd(data) {
    return post('adminapi/regional_add',data)
  }
  //地区更新
export function PostRegionalUpdate(data) {
    return post('adminapi/regional_update',data)
  }
    //地区删除
export function PostRegionalDelete(data) {
    return post('adminapi/regional_delete',data)
  }
  