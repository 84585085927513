<template>
    <div class="user">
      <!-- 面包屑导航 -->
      <el-card>
        <!-- 头 -->
        <el-row :gutter="20">
          <el-col :span="5">
            <el-input placeholder="请输入内容" v-model="getInfo.info">
              <el-button slot="append" icon="el-icon-search"></el-button>
            </el-input>
          </el-col>
          <el-col :span="8"> </el-col>
          <el-tooltip
            class="item"
            effect="dark"
            content="增加区域"
            placement="top-start"
          >
            <el-button  type="primary" @click="addPeoBtn">增加区域</el-button>
          </el-tooltip>
        </el-row>
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          v-if="tableData"
        >
              <el-table-column
            align="center"
            prop="regional_name"
            label="地区名称"
          >
          </el-table-column>
              <el-table-column
            align="center"
           
            label="位置"
          >
          <template slot-scope="scope">
            <div v-for="item in JSON.parse(scope.row.longitude_latitude_json)" :key="item.regional_id">
                {{item.longitude}},{{ item.latitude }}
            </div>
          </template>
          </el-table-column>
          <el-table-column align="center" label="操作">
            <template slot-scope="scope">
              <!-- <el-tooltip
                class="item"
                effect="dark"
                content="查看站点详情"
                placement="top-start"
              >
                <el-button
                  icon="el-icon-search"
                  @click="lookSite(scope.row)"
                ></el-button>
              </el-tooltip> -->
              <el-tooltip
                class="item"
                effect="dark"
                content="修改区域"
                placement="top-start"
              >
                <el-button
                  icon="el-icon-edit"
                  type="primary"
                  @click="updataPicture(scope.row)"
                ></el-button>
              </el-tooltip>
              <el-tooltip
                class="item"
                effect="dark"
                content="删除区域"
                placement="top-start"
              >
                <el-button
                  icon="el-icon-delete"
                  type="danger"
                  @click="delPicture(scope.row.regional_id)"
                ></el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
          @current-change="pageChange"
          @size-change="sizeChange"
          :page-sizes="[7, 15, 20]"
          layout="total, sizes, prev, pager, next"
          :page-size="this.getInfo.page_num"
          background
          :total="exp.num"
        >
        </el-pagination>
      </el-card>
       <!-- 增加区域模态框 -->
      <el-dialog
        :visible.sync="dialogFormVisible"
        @close="userClose(form)"
        :append-to-body="true"
         
      >
      <div slot="title" v-if="updataFalg" class="dialog-footer" >
          <div class="title" >修改区域</div>
        </div>
         <!-- <div slot="title" v-else-if="lookFalg" class="dialog-footer" >
          <div class="title" >查看人数</div>
        </div> -->
        <div slot="title" v-else class="dialog-footer" >
          <div class="title" >增加区域</div>
        </div>
        <div class="diaBox">
        <el-form :model="form" ref="ruleForm"  :rules="rules">
                         <el-form-item label="地区名称" :label-width="formLabelWidth" prop="regional_name">
                                   <el-input v-model="form.regional_name" style="width: 200px;"></el-input>
    </el-form-item> 
    <el-form-item label="经纬度" :label-width="formLabelWidth" prop="longitude_latitude_json" >
        <div v-for="(item,index) in this.form.longitude_latitude_json" :key="index" style="margin-bottom: 10px;">
                 <el-input v-model="item.longitude" style="width: 200px;margin-right: 10px;" placeholder="请输入经度"></el-input>
                                   <el-input v-model="item.latitude" style="width: 200px;margin-right: 10px;" placeholder="请输入维度"></el-input>
                                   <el-button v-if="item.addForm" @click="addFormInput(index)">增加</el-button>
                                   <el-button v-if="item.delForm" @click=delFormInput(index)>删除</el-button>
        </div>
                              
                                   
    </el-form-item> 
        </el-form>
         </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button v-if="addFlag" type="primary" @click="addArea('ruleForm')"
            >添 加</el-button
          >
          <!-- <el-button v-else-if="lookFalg" type="primary" @click="dialogFormVisible = false"
            >确 认</el-button
          > -->
         <el-button v-else type="primary" @click="updSiteSer('ruleForm')"
            >修 改</el-button
          >
        </div>
      </el-dialog>
    </div>
  </template>
  
  <script>
  //这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
  //例如：import 《组件名称》 from '《组件路径》';
   import {
    PostRegionalList,
    PostRegionalAdd,
    PostRegionalUpdate,
    PostRegionalDelete
} from "@/api/areaList/areaList.js"
  
  export default {
    //import引入的组件需要注入到对象中才能使用
    components: {},
    data() {
          var validateRegionalName = (rule, value, callback) => {
          if (this.form.regional_name === '') {
            callback(new Error('请输入区域名称'));
          } else {
            callback();
          }
        };
        var validatelongitudeLatitudeJson = (rule, value, callback) => {
            this.form.longitude_latitude_json.forEach((item)=>{
	    if((item['longitude']!= null && item['longitude']!= "")&&(item['latitude']!= null && item['latitude']!= "")){
		    console.log(item['longitude']!= null)
            this.isEmpty = false;
	    }
            }) 
          if (this.isEmpty) {
            callback(new Error('请输入经纬度'));
          } else {
            callback();
          }
        };
      //这里存放数据
      return {
           rules: {
            regional_name: [
              { validator: validateRegionalName, trigger: 'blur' },      
            ],
            longitude_latitude_json: [
            { validator: validatelongitudeLatitudeJson, trigger: 'blur' },      
            ],            
          },
        //总条数
        exp: {
          count: Number,
          num: 0,
        },
        //当前页码
        getInfo: {
          //搜索框
          info: "",
          page_code: 1,
          page_num: 15,
        },
        isEmpty:true,
        form:{
            regional_id:'',
            regional_name:'',
            longitude_latitude_json:[{
                longitude:'',
                latitude:'',
                addForm:true,
                delForm:false
            }]
        },
        props:{
          children:'sub',
          value:'id',
          label:'name'
        },
        //表格数据
           tableData: [],
              token: {
          token: window.sessionStorage.getItem("token"),
        },
        formLabelWidth: "100px",
         imgParam: {
          path: "contract/template",
        },
          fileList: [],
          //控制弹窗--新增修改
        dialogFormVisible: false,
        addFlag:false,
        lookFalg:false,
        updataFalg:false,
         options: [],
           options1: [{
            value: 0,
            label: '上门服务模板'
          }, {
            value: 1,
            label: '场地预约模板'
          }],
      };
    },
    //监听属性 类似于data概念
    computed: {
          imgUrl() {
        return this.$store.state.imgUrl;
      },
    },
    //监控data中的数据变化
    watch: {
    },
    //方法集合
    methods: {
        delPicture(regional_id){
            this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
            this.PostRegionalDelete({
            regional_id:regional_id
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
        
        },
        addArea(formName){
            this.$refs[formName].validate((valid) => {
          if (valid) {
            var dataObj={
                regional_name:this.form.regional_name,
                longitude_latitude_json:JSON.stringify(this.form.longitude_latitude_json)
            }
            this.PostRegionalAdd(dataObj);
          //  alert('submit!');
          } else {
            console.log('error submit!!');
            return false;
          }
        });
        },
        updSiteSer(formName){
            this.$refs[formName].validate((valid) => {
          if (valid) {
            var dataObj={
                regional_id:this.form.regional_id,
                regional_name:this.form.regional_name,
                longitude_latitude_json:JSON.stringify(this.form.longitude_latitude_json)
            }
            this.PostRegionalUpdate(dataObj);
          //  alert('submit!');
          } else {
            console.log('error submit!!');
            return false;
          }
        });
        },
        //增加表单Input
        addFormInput(index){
                this.form.longitude_latitude_json.splice(index+1,0,{
                longitude:'',
                latitude:'',
                addForm:true,
                delForm:true
           })
        },
        
        updataPicture(item){
          //  console.log(item)
          this.form.regional_id=item.regional_id;
          this.dialogFormVisible=true;
          this.updataFalg=true;
          this.form.regional_name=item.regional_name;
          this.form.longitude_latitude_json=JSON.parse(item.longitude_latitude_json)
        },
        //删除表单Input
        delFormInput(index){
            this.form.longitude_latitude_json.splice(index,1)
        },
      //增加站点
      addPeoBtn(){
          this.dialogFormVisible=true;
         this.addFlag=true;
         // this.updataFalg=true;
      },
      //查看站点
      lookSite(){
          
      },
      //修改每页数量
      sizeChange(value) {
        this.getInfo.page_num = value;
        var data = {
          page: this.getInfo.page_code,
          list_rows: this.getInfo.page_num,
        };
        console.log(data);
        this.tableData = [];
       // this.getPeopleList(data);
      },
      //分页切换
      pageChange(value) {
        this.getInfo.page_code = value;
        var data = {
          page: this.getInfo.page_code,
          list_rows: this.getInfo.page_num,
        };
        console.log(data);
        this.tableData = [];
      //  this.getPeopleList(data);
      },
            exceed() {
        this.$message({
          message: "数量超出",
          type: "warning",
        });
      },
      //导入图片事件
      imgSuccess(response, file, fileList) {
          // console.log(response)
        this.form.url = response.data.url;
      },
  
      //移除图片的事件
      imgRemove(file, fileList) {
        this.form.url = "";
      },
             //添加事件 退出弹窗
      userClose(e) {
        Object.keys(this.form).forEach(key => (this.form[key] = ''));
        this.dialogFormVisible=false;
        this.addPeoBtn=false;
        this.updataFalg=false;
        (this.fileList = []), console.log("close");
      },
      /**-----------------网络请求生命周期函数--------------------- */
       //区域列表
      async PostRegionalList(data){
          let res=await PostRegionalList(data);
          this.tableData=res.data.result.data;
          this.getInfo={
              page_code:res.data.result.last_page,
              list_rows:res.data.result.per_page
          }
            this.exp.num=res.data.result.total;
          //   this.dialogFormVisible=false;
          // console.log(res.data.data);
      },
      //区域列表增加
      async PostRegionalAdd(dataObj){
         let res=await PostRegionalAdd(dataObj);
         if(res.data.status==1){
            this.dialogFormVisible=false;
            this.$message({
          message: res.data.message,
          type: 'success'
        });
        var data = {
        page: this.getInfo.page_code,
        list_rows: this.getInfo.page_num,
      };
       this.PostRegionalList(data);
         }else{
            this.$message.error(res.data.message)
         }
      },
        //区域列表修改
        async PostRegionalUpdate(dataObj){
         let res=await PostRegionalUpdate(dataObj);
         if(res.data.status==1){
            this.dialogFormVisible=false;
            this.$message({
          message: res.data.message,
          type: 'success'
        });
        var data = {
        page: this.getInfo.page_code,
        list_rows: this.getInfo.page_num,
      };
       this.PostRegionalList(data);
         }else{
            this.$message.error(res.data.message)
         }
      },
        //区域列表删除
        async PostRegionalDelete(dataObj){
         let res=await PostRegionalDelete(dataObj);
         if(res.data.status==1){
            this.dialogFormVisible=false;
            this.$message({
          message: res.data.message,
          type: 'success'
        });
        var data = {
        page: this.getInfo.page_code,
        list_rows: this.getInfo.page_num,
      };
       this.PostRegionalList(data);
         }else{
            this.$message.error(res.data.message)
         }
      },

    },  
    beforeCreate() {}, //生命周期 - 创建之前
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {
      var data = {
        page: this.getInfo.page_code,
        list_rows: this.getInfo.page_num,
      };
       this.PostRegionalList(data);
    },
  
    beforeMount() {}, //生命周期 - 挂载之前
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {},
    beforeUpdate() {}, //生命周期 - 更新之前
    updated() {}, //生命周期 - 更新之后
    activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    beforeDestroy() {}, //生命周期 - 销毁之前
    destroyed() {}, //生命周期 - 销毁完成
  };
  </script>
  
  <style scoped>
  .page {
    margin-top: 20px;
  }
  .title {
    font-size: 18px;
    font-weight: bold;
  }
  .set-page {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .set-page-left {
    color: #0000006d;
    font-size: 14px;
    font-weight: 400;
  }
  .title {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
  .diaBox {
    display: flex;
    justify-content: center;
  }
  .imgUrl {
    margin-left: 50%;
    transform: translateX(-50%);
    width: 250px;
    height: 150px;
    overflow: hidden;
    text-align: center;
  }
  </style>